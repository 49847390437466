export default {
    // 获取用户登陆信息
    getUser: () => {
        return !!JSON.parse(localStorage.getItem('USER_INFO'))
    },
    // 清除用户缓存信息(记住的登录信息除外)
    clearUser: () => {
        localStorage.clear()
    },
    getBrowser() {
        const userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
        // 判断是否Opera浏览器
        // if (isOpera) {
        //     return "Opera"
        // };
        // 判断是否Edge浏览器
        if (userAgent.indexOf('Edge') > -1) {
            return 'Edge'
        }
        // 判断是否Firefox浏览器
        if (userAgent.indexOf('Firefox') > -1) {
            return 'FF'
        }
        // 判断是否chorme浏览器
        if (userAgent.indexOf('Chrome') > -1) {
            return 'Chrome'
        }
        // 判断是否Safari浏览器
        if (userAgent.indexOf('Safari') > -1) {
            return 'Safari'
        }
        // 判断是否IE浏览器
        if (userAgent.indexOf('.NET ') > -1) {
            return 'IE'
        }
    }
}
