// 处理http请求
import Utils from '@a/js/utils'
import Axios from 'axios'
import Qs from 'querystring'
import { Notification } from 'element-ui'
import Vue from 'vue'
// 解决IE get请求缓存
// console.log('当前浏览器：', Utils.getBrowser());
const instance = Axios.create({
    baseURL: '',
    timeout: 3000
    // headers: { 'X-Custom-Header': 'foobar'
})

if (Utils.getBrowser() === 'IE') {
    instance.defaults.headers['no-cache'] = 'no-cache'
    instance.defaults.headers['Pragma'] = 'no-cache'
}

instance.interceptors.request.use(config => {
    config.data = Qs.stringify(config.data)
    return config
}, error => {
    return Promise.reject(error)
})

instance.interceptors.response.use(function(res) {
    console.log('res', res)
    switch (res.status) {
        case (200 || 304):
        // 超时code
            if (res.data.code !== 1001) {
                Utils.clearUser()
                location.href = '/'
            } else {
            // Notification({ title: '提示', type: res.data.detail.status === 1 ? 'success' : 'error', message: res.data.msg, position: 'bottom-right' });
                return res.data
            }
            break
        default:
            return res.data
    }
}, function(error) {
    console.log('error', error)
    if (error && error.response) {
        console.log('error && error.response', error)
        // Notification({ title: Vue.i18n.translate('提示'), type: 'error', message: Vue.i18n.translate(error.response.data.message), position: 'bottom-right' });
    } else {
        console.log('error', error)
        // Notification({ title: Vue.i18n.translate('提示'), type: 'error', message: Vue.i18n.translate('网络出现问题，请稍候重试'), position: 'bottom-right' })
    }
    return Promise.resolve(error.response)
})

export default instance
