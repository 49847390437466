import Vue from 'vue'
import router from './router'
import Axios from 'axios'
import Utils from '@a/js/utils'
import querystring from 'querystring'
import store from './store'
import App from './App.vue'
// 请求拦截
import $http from '@api/http'

// 按需引入elementUI
import '@a/js/initElementUI'
import './assets/scss/main.scss'

Vue.config.productionTip = false

const addMethod = {
    $utils: Utils,
    $qs: querystring,
    $http: $http,
    processEnv: process.env.NODE_ENV // 环境变量
}
Vue.prototype = Object.assign(Vue.prototype, addMethod)

router.beforeEach(async(to, from, next) => {
    console.log('to-from', to, from)
    next()
})

new Vue({
    router,
    store,
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    },
    render: h => h(App)
}).$mount('#app')
