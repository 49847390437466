import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@v/home/index.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '@v/login/index.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "register" */ '@v/register/index.vue')
    },
    {
        path: '/password',
        name: 'Password',
        component: () => import(/* webpackChunkName: "password" */ '@v/password/index.vue')
    },
    {
        path: '/news',
        name: 'News',
        // route level code-splitting
        // this generates a separate chunk (news.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "news" */ '@v/news/index.vue')
    },
    {
        path: '/news/detail',
        name: 'NewsDetail',
        component: () => import(/* webpackChunkName: "news-detail" */ '@v/news/detail/index.vue')
    },
    {
        path: '/product',
        name: 'Product',
        component: () => import(/* webpackChunkName: "product" */ '@v/product/index.vue')
    },
    {
        path: '/product/detail',
        name: 'ProductDetail',
        component: () => import(/* webpackChunkName: "product-detail" */ '@v/product/detail/index.vue')
    }
]
const router = new VueRouter({
    // mode: 'history',
    routes
})
export default router
